import type { ControllerParams } from '@wix/yoshi-flow-editor';

import refactoring from '../settings/__migrations__/global-refactoring';

/**
 * Viewer only
 * will migrate styleParams
 */
export function migrateStyles(params: ControllerParams) {
  const { isViewer } = params.flowAPI.environment;
  const { setProps, config } = params.flowAPI.controllerConfig;

  if (isViewer) {
    setProps(
      params.dangerousStylesOverride({
        ...refactoring(config.style.styleParams, config.publicData),
      }),
    );
  }
}
